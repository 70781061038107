<template>
  <div class="about">
    <!-- 浏览记录车辆 -->
    <!-- <div @click="countadd(10)" v-for="(item,index) in this.$store.state.count" :key="index">{{item}}</div> -->
    <!-- <div class="container">
      <div class="card-list">
        <div
          class="card-item"
          v-for="(item, index) in this.$store.state.recordCars"
          :key="index"
        >
          <div
            v-if="item.car_pic"
            class="pic"
            :style="[
                {
                  backgroundImage: 'url(' + baseurl + item.car_pic + ')',
                },
              ]"
          ></div>
          <el-tooltip class="item" effect="dark" :content="item.car_name" placement="top-start">
            <p class="card-name">{{ item.car_name }}</p>
          </el-tooltip>
          <p class="card-time">{{ item.car_age }}/{{ item.travel_km }}</p>
          <p class="price">
            <span class="proce-unit">￥</span>
            {{ item.sale_price }}万
            <span class="old-price">¥{{ item.price }}万</span>
          </p>
        </div>
      </div>
    </div> -->
    <!-- 背景图 -->
    <div class="img-back">
      <img src alt />
    </div>
    <div class="content">
      <div class="about-box" v-html="aboutcontent">
        <!-- <div class="about-list" v-for="(item,index) in about" :key="index">
          <div class="img-box">
            <img src alt />
          </div>
          <div class="title">{{item.title}}</div>
          <div class="info">{{item.info}}</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { guarantee } from "@/api/index.js";
export default {
  data() {
    return {
      aboutcontent: "",
      about: [
        {
          imgurl: "",
          title: "品牌简介",
          info:
            "悦心车品牌于2019年11月21日正式上线，是国内首家以会员服务为核心、专注于二手车零售服务的电商平台，主要业务模式为二手车严选B2C。立志于为消费者提供可追溯的品质二手车及优质的售前售后体验。"
        },
        {
          imgurl: "",
          title: "服务范围",
          info:
            "截止到目前，悦心车的服务网络已经覆盖全国90个大中城市。品牌拥有完善强大的汽车收检能力，已在全国组建了近千人专业评估师团队，并接受多家第三方检测机构联合抽检，确保质量。所有车辆经专业检测和整备并通过权威机构抽检后，才可以最终呈现到悦心车电商平台上，消费者可通过悦心车网站以及微信小程序浏览，随时随地选购心仪的二手车。"
        },
        {
          imgurl: "",
          title: "服务体系",
          info:
            "悦心车以为消费者提供优质可靠的二手车为核心，具备可溯源的二手汽车交易服务能力。专注把控二手车交易过程中各个环节品质流程，对车源负责，对售后承诺。流程环节包括检测、评估、整备、1对1专属售前售后客户服务，并提供汽车延保、分期购、以租代购等优质灵活的服务方案。真正做到全程品质可追溯，每一辆都可靠。"
        }
      ]
    };
  },
  mounted() {
    this.guarantee();
  },
  methods: {
    // countadd(ll) {
    //   this.$store.commit("addcount",ll)
    // },
    // 关于我们富文本
    guarantee() {
      guarantee({}).then(res => {
        this.aboutcontent = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.about {
  width: 100%;
  background-color: #fff;
  padding-bottom: 50px;
  box-sizing: border-box;

  .container {
    width: 100%;
    margin-top: 102px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        display: inline-block;
        width: 93px;
        height: 1px;
        background: #000000;
        opacity: 0.1;
      }
      .text {
        color: #0097e2;
        font-size: 30px;
        font-weight: 500;
        margin: 0 19px;
      }
    }
    .card-list {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .card-item {
        width: 286px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(244, 244, 244, 1);
        margin-right: 17px;
        margin-top: 33px;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .pic {
          width: 100%;
          height: 203px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .card-name {
          font-size: 16px;
          font-weight: 300;
          color: #000000;
          margin-left: 10px;
          margin-top: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .card-time {
          font-size: 16px;
          font-weight: 300;
          color: #0097e2;
          margin-left: 10px;
          margin-top: 4px;
          margin-bottom: 13px;
        }
        .price {
          font-size: 18px;
          padding-left: 10px;
          color: #fff;
          background: #0097e2;
          font-weight: bold;
          .proce-unit {
            font-size: 14px;
            color: #fff;
            font-weight: bold;
          }
          .old-price {
            font-size: 16px;
            font-weight: 300;
            text-decoration: line-through;
            color: #fff;
            margin-left: 12px;
          }
        }
      }
    }
    .see-more {
      width: 190px;
      height: 42px;
      background: rgba(0, 151, 226, 1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      font-weight: 400;
      color: #fff;
      margin: 58px auto 127px;
      cursor: pointer;
    }
  }

  .img-back {
    width: 100%;
    min-width: 1200px;
    height: 400px;
    padding-top: 60px;
    box-sizing: border-box;
    background-image: url("https://www.yuexinche.com/pic/pc6.jpg");
    background-size: cover;
    background-position: center;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // img{
    //   max-width: 100%;
    //   max-height: 100%;
    // }
  }

  .content {
    width: 1200px;
    min-width: 1200px;
    height: auto;
    background-color: #fbfbfb;
    position: relative;
    top: -60px;
    margin: 0 auto;
    padding: 100px 65px 80px;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .about-box {
      width: 100%;
      display: flex;
      .about-list {
        width: 32%;
        margin-right: 2%;
        background-color: #f5f3f2;
        .img-box {
          width: 100%;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: red;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .title {
          padding: 0 35px;
          box-sizing: border-box;
          font-size: 21px;
          color: #000;
          font-weight: bold;
          margin-top: 45px;
        }
        .info {
          padding: 0 35px 50px;
          box-sizing: border-box;
          font-size: 14px;
          color: #000;
          line-height: 30px;
          margin-top: 10px;
        }
        &:last-child {
          margin-right: 2%;
        }
      }
    }
  }
}
</style>
